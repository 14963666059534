import { useContext } from "react";
import LinkCard from "components/card/LinkCard";
import LocationTag from "components/ui/Tag/LocationTag";

import { CurrencyContext } from "contexts/currency";

const ExperienceLinkCard = ({
  experience,
  containerStyles,
  titleHeight,
  cardProps,
  headerFontSize,
  loading = "lazy",
}) => {
  const currencyContext = useContext(CurrencyContext);
  const currency = currencyContext.currency;

  const cardLocation = experience?.location?.[0] ? (
    <LocationTag location={experience?.location[0]?.country?.countryName} />
  ) : (
    ""
  );

  return (
    <LinkCard
      title={experience?.experience_title}
      slug={`/experience/${experience?.slug}`}
      image={experience?.seo?.metaMedia?.url}
      objectId={experience?.id}
      data1={cardLocation}
      data2={`${currency?.symbol ?? "$"}${
        experience.metadata?.currencies?.[currency?.code.toLowerCase()] ?? "-"
      }/week`}
      headerFontSize={headerFontSize}
      containerStyles={containerStyles}
      titleHeight={titleHeight ? titleHeight : 2}
      tags={
        experience?.experience_categories
          ? [experience?.experience_categories[0]?.category_name]
          : []
      }
      loading={loading}
      favorite={true}
      favoriteType="experiences"
      {...cardProps}
    />
  );
};

export default ExperienceLinkCard;
